import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useStyles } from "./StudyCasesStyle";
import StudyCaseCard from "./StudyCaseCard";
import AnchorLink from "react-anchor-link-smooth-scroll";

const StudyCases = () => {
  const classes = useStyles();

  const imageURL = "StudyCaseLogo.png";

  const [showMore, setShowMore] = useState(false);

  const data = [
    {
      description:
        "Développer une solution dédiée pour un flux de travail plus efficace",
      img: "Clemessy-essai.jpeg",

      pdfName: "Etude-de-cas_Clemessy.pdf",
    },
    {
      description:
        "Rénovation majeure d’une vaste station d’épuration : Succès grâce à la modélisation BIM",
      img: "Achere-site.png",
      pdfName: "Etude-de-cas_Acheres.pdf",
    },
    {
      description:
        "Comment Améliorer l’Accessibilité dans une Gare grâce à la Modélisation 3D BIM",
      img: "Meudon-sonabim.png",
      pdfName: "Etude-de-cas_Meudon.pdf",
    },
    {
      description:
        "Modélisation d’un complexe hospitalier pour la gestion des données sur Open Maint",
      img: "CNHP.png",

      pdfName: "Etude-de-cas_CHNP.pdf",
    },
    {
      description:
        "Simplifiez votre flux de travail avec des plugins sur mesure",
      img: "Suez.png",
      pdfName: "Etude-de-cas_Groupe Suez.pdf",
    },
    {
      description:
        "Restaurer la Splendeur du Passé : Évaluation de l’Ossature Métallique de la Samaritaine – Magasin 3",
      img: "samaritaine_sonabimjpg.jpg",
      pdfName: "Etude-de-cas_La-Samaritaine_SONABIM.pdf",
    },
  ];

  const filteredData = showMore ? data : data.slice(0, 3);

  const handleExploreMoreClick = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };

  return (
    <Box className={classes.MainContainer} id="etude-de-cas">
      <div data-aos="zoom-out" data-aos-delay="150">
        <Typography className={classes.StudyCasesText}>
          Etude de <span className={classes.StudyCasesSpan}>cas</span>
        </Typography>
      </div>

      <Box className={classes.StudyCaseCardWrapper}>
        {filteredData.map((item, index) => (
          <StudyCaseCard
            key={index}
            index={index}
            data={item}
            pdfName={item.pdfName}
          />
        ))}
      </Box>
      <Box className={classes.ExploreMoreButtonWraapper}>
        <div data-aos="zoom-out">
          {showMore && (
            <AnchorLink
              href="#etude-de-cas"
              key="etude-de-cas"
              offset={-100}
              className={classes.ExploreMoreButton}
            >
              <Button
                variant="outlined"
                className={classes.ExploreMoreButton}
                onClick={handleExploreMoreClick}
              >
                Afficher moins
              </Button>
            </AnchorLink>
          )}
          {!showMore && (
            <AnchorLink
              href="#etude-de-cas"
              key="etude-de-cas"
              offset={-700}
              className={classes.ExploreMoreButton}
            >
              <Button
                variant="outlined"
                className={classes.ExploreMoreButton}
                onClick={handleExploreMoreClick}
              >
                Explore plus
              </Button>
            </AnchorLink>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default StudyCases;
