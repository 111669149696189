// import React, { useEffect } from "react";
// import Stack from "@mui/material/Stack";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
// import { Box } from "@mui/material";
// import { useStyles } from "./SnackbarStyle";

// const Alert = React.forwardRef(function Alert(props, ref) {
//   const classes = useStyles();
//   return (
//     <MuiAlert className={classes.Alert} elevation={6} ref={ref} {...props} />
//   );
// });

// export default function CustomizedSnackbars({ OpenSnackBar, setOpenSnackBar }) {
//   const classes = useStyles();

//   useEffect(() => {
//     setOpenSnackBar(OpenSnackBar);
//     setTimeout(() => {
//       setOpenSnackBar(false);
//     }, 7000);
//   }, [OpenSnackBar]);

//   return (
//     <Snackbar
//       open={OpenSnackBar}
//       autoHideDuration={1000}
//       // anchorOrigin={{
//       //   vertical: "top",
//       //   horizontal: "right",
//       // }}
//       className={classes.SnackBar}
//       action={
//         <Box className={classes.SnackBarWrapper}>
//           <Alert sx={{ width: "100%" }}>Votre message a été envoyé</Alert>
//           <IconButton
//             size="small"
//             aria-label="close"
//             onClick={() => {
//               setOpenSnackBar(false);
//             }}
//           >
//             <CloseIcon fontSize="small" />
//           </IconButton>
//         </Box>
//       }
//     />
//   );
// }

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useStyles } from "./SnackbarStyle";
import CloseIcon from "@mui/icons-material/Close";

export default function AlertDialog({ OpenSnackBar, setOpenSnackBar, Title }) {
  const classes = useStyles();

  const handleClose = () => {
    setOpenSnackBar(false);
  };

  return (
    <div>
      <Dialog
        open={OpenSnackBar}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={classes.DialogContent}>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.ModalTitle}
          >
            {Title}
          </DialogContentText>
          <CloseIcon className={classes.CloseIcon} onClick={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
