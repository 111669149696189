import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  Container: {
    backgroundColor: "#1F3052",
    width: "100%",
  },
  MainContainer: {
    width: "80%",
    margin: "auto",
    paddingTop: "5rem",
    paddingBottom: "5rem",
  },
  HeadingContainer: {
    flexDirection: "column",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  Heading: {
    "&.MuiTypography-root": {
      fontWeight: "600",
      color: "#49A995",
      fontFamily: "'Poppins', sans-serif",
      textAlign: "center",

      "@media(max-width: 420px)": {
        fontSize: "45px",
        textAlign: "center",
      },
    },
  },

  SonabinTitle: {
    "&.MuiTypography-root": {
      fontWeight: "600",
      color: "#262626",
      fontFamily: "'Poppins', sans-serif",

      "@media(max-width: 420px)": {
        fontSize: "25px",
        textAlign: "center",
      },
    },
  },

  SonabinTitle: {
    "&.MuiTypography-root": {
      fontWeight: "600",
      color: "#49A995",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "33px",
      marginTop: "3rem",
    },
  },

  SonabinTitleSpan: {
    fontWeight: "600",
    color: "#FFF",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "28px",
  },

  ContectInfoContainer: {
    marginTop: "4rem",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },

  HeadingText: {
    "&.MuiTypography-root": {
      fontWeight: "600",
      color: "#49A995",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "30px",
      marginTop: "2rem",
      marginBottom: "2rem",
    },
  },

  Paragraphy: {
    fontFamily: "'Poppins', sans-serif",
    lineHeight: "1.4",
    fontSize: "16px",
    fontWeight: "400",
    paddingTop: "25px",
    color: "white",
  },

  point: {
    "&.MuiTypography-root": {
      fontSize: "18px",
      paddingTop: "5px",

      fontWeight: "600",
      color: "#FFF",
    },
  },

  PointParagraphy: {
    "&.MuiTypography-root": {
      fontFamily: "'Poppins', sans-serif",
      lineHeight: "1.4",
      fontSize: "15px",
      fontWeight: "400",
      paddingTop: "4px",
      color: "white",
    },
  },

  telephone: {
    "&.MuiTypography-root": {
      fontFamily: "'Poppins', sans-serif",
      lineHeight: "1.4",
      fontSize: "15px",
      fontWeight: "400",
      paddingTop: "4px",
      color: "white",
      paddingTop: "2rem",
    },
  },

  paraEmail: {
    color: "#309fd2",
    paddingLeft: "2px",
    "&:hover": {
      color: "#49A995",
    },
  },

  Number: {
    color: "#309fd2",
    fontSize: "18px",
  },

  plus: {
    color: "#2f2d2d",
  },

  BackButton: {
    "&.MuiButtonBase-root": {
      color: "black",
      margin: "18px",
      width: "63px",
      height: "63px",
      borderRadius: "100px",
      backgroundColor: "#49A995",
      color: "#FFF",

      position: "fixed",
      "&:hover": {
        backgroundColor: "#1F3052",
        color: "white",
      },
    },
  },

  UnorderList: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  UpButton: {
    "&.MuiButtonBase-root": {
      color: "black",
      width: "63px",
      height: "63px",
      borderRadius: "100px",
      backgroundColor: "#49A995",
      color: "#FFF",
      bottom: "0",
      right: "0",
      margin: "18px",
      alignItems: "center",
      justifyContent: "center",

      position: "fixed",
      "&:hover": {
        backgroundColor: "#1F3052",
        color: "white",
      },
    },
  },
}));
