import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./OurSloutionsStyle";
import Transportation from "./Transportation";
import DescriptionModal from "./DescriptionModal";

const OurSloutions = () => {
  const [openModal, setOpenModal] = useState(false);
  const items = [
    {
      img: "Modelization.png",
      text: "Modélisation 3D",
      description:
        "La modélisation 3D consiste à créer une représentation virtuelle en trois dimensions d'une installation industrielle ou d'un projet. Cela permet une visualisation en 3dimensions et précise de l'ensemble du projet. Les avantages incluent la capacité à anticiper les problèmes potentiels en visualisant le site dans son intégralité, à mieux planifier le projet en identifiant des zones de conflit ou des besoins spécifiques, et à faciliter la communication en utilisant des représentations visuelles pour expliquer des concepts complexes",
    },
    {
      img: "simulation.png",
      text: "La Simulation",
      description:
        "La simulation consiste à créer des modèles virtuels pour prévoir le comportement d'un système ou d'un projet dans différentes situations (démantèlement, construction, implémentation de nouveaux process). Cela peut inclure des simulations de flux de travail, de rendement, de sécurité, etc. La simulation aide à prendre des décisions éclairées en testant des scénarios et en évaluant les conséquences sans risque ni coût pour le projet réel",
    },
    {
      img: "diagnostic.png",
      text: "Le Diagnostic",
      description:
        " Le diagnostic implique l'utilisation d'outils et de données pour évaluer l'état actuel d'un projet ou d'un système. Cela peut servir à identifier les problèmes potentiels, les zones de sous-performance ou les opportunités d'amélioration. Les diagnostics permettent de prendre des mesures correctives pour optimiser le projet.",
      subDescription:
        "SONABIM possède une vision à 180° des outils de diagnostic depuis le scan 3D jusqu’au modèle BIM ce qui permet d’aller vite et d’ulitser les bons outils directement sans créer une grosse base de données difficile à faire évoluer ensuite.",
    },
    {
      img: "commisioning.png",
      text: "E-Commissioning",
      description:
        "Nous utilisons nos jumeaux numériques de manière innovante pour faciliter la réalisation des opérations de mise en service d'installations complexes. Ces modèles numériques nous offrent une panoplie d'avantages considérables. Tout d'abord, ils nous permettent de centraliser la documentation de chaque appareil, rassemblant ainsi tous les détails techniques, les spécifications, et les procédures d'exploitation au même endroit. Cette centralisation simplifie grandement la gestion des données, améliore l'accessibilité aux informations cruciales, et réduit les risques d'erreurs humaines liées à la gestion de la documentation papier.",
    },
    {
      img: "managment.png",
      text: "Gestion des données",
      description:
        "La gestion des données implique la collecte, le stockage et l'organisation d'informations cruciales pour les projets, fournissant des informations en temps réel pour des décisions efficaces en gestion de projet, planification des ressources et contrôle des coûts.",
    },
    {
      img: "Conflict.png",
      text: "Résolution de conflit dans le “Workflow",
      description:
        " Notre service de Résolution des Conflits dans le Workflow repose sur la création et l'intégration de plugins personnalisés conçus spécifiquement pour votre entreprise. Ces plugins sont des outils informatiques qui s'intègrent parfaitement dans vos systèmes et logiciels existants pour aider à anticiper, identifier et résoudre les conflits de manière proactive",
    },
    {
      img: "tracking.png",
      text: "Suivi en temps réel",
      description:
        "Cette solution repose sur la collecte de données en temps réel pour suivre l'avancement du projet. Elle permet de comparer l'état actuel du projet avec le planning initial, d'identifier les retards potentiels et de prendre des mesures correctives. Le suivi en temps réel est essentiel pour respecter les délais et le budget du projet",
    },
    {
      img: "dismantling.png",
      text: "démantèlement",
      description:
        "Nous vous accompagnons dans le démembrement de vos actifs en créant un jumeau numérique pour chaque étape :",
      points: [
        "Planifier le démantèlement : Utilisation de modèles numériques pour une planification efficace.",
        "Gérer les déchets : Estimation précise des matériaux récupérables et des déchets",
        "Améliorer la sécurité : Identification des risques et mise en place de procédures sécuritaires.",
        "Documenter les matériaux : Documentation des types de matériaux utilisés.",
        "Faciliter la collaboration entre les parties prenantes : Favorisation de la communication entre les acteurs du projet.",
      ],
    },
  ];

  const classes = useStyles();
  return (
    <Box className={classes.MainContainer} id="nos-solutions">
      <Box className={classes.HeadingBox}>
        <div data-aos="flip-up" data-aos-delay="200">
          <Typography className={classes.TitleSolutionHeading}>
            Nos{" "}
            <span className={classes.TitleSolutionHeadingSpan}>solutions</span>
          </Typography>
        </div>
        <div data-aos="zoom-in-up">
          <Typography className={classes.HeadingBoxParagraphy}>
            offrent à nos clients des solutions pratiques aux résultats
            <br />
            immédiatement tangibles
          </Typography>
        </div>
        <Box className={classes.TransportationMainContainer}>
          <Transportation data={items} setOpenModal={setOpenModal} />
        </Box>
      </Box>
    </Box>
  );
};

export default OurSloutions;
