import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useStyles } from "./ActivitesSectionStyle";

const YourActivity = ({ Title, src, about, subText }) => {
  const classes = useStyles();
  const [showSubText, setShowSubText] = useState(false);

  const handleToggleSubText = () => {
    setShowSubText(!showSubText);
  };

  return (
    <Box
      className={
        Title === "Complexe Industrie"
          ? classes.YourActivityContainerComplexe
          : classes.YourActivityContainer
      }
    >
      <img src={src} alt="" />
      <Typography className={classes.YourActivityHeading}>{Title}</Typography>
      <Typography className={classes.YourActivityPara}>
        {about}
        {showSubText && subText}
      </Typography>
      <Button
        variant="text"
        className={classes.savoirBtn}
        onClick={handleToggleSubText}
      >
        {showSubText ? "Cacher" : "En savoir plus"}
      </Button>
    </Box>
  );
};

export default YourActivity;
