import React, { useState } from "react";
import { Box, Button, Drawer, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useStyles } from "./HomePageHeaderStyle";
import CloseIcon from "@mui/icons-material/Close";
import Buttons from "../Buttons/Buttons";

const ResponsiveNavbar = () => {
  const classes = useStyles();

  const [state, setState] = useState({
    right: false, // Set the initial state to open the right drawer
  });

  const toggleDrawer = (anchor, open) => () => {
    setState({ ...state, [anchor]: open });
  };

  const navItems = [
    <AnchorLink
      href="#accueil"
      key="accueil"
      offset="100"
      className={classes.ResNavLinks}
    >
      Accueil
    </AnchorLink>,
    <AnchorLink
      href="#vos-activites"
      key="vos-activites"
      offset="-170"
      className={classes.ResNavLinks}
    >
      Vos Activités
    </AnchorLink>,
    <AnchorLink
      href="#nos-solutions"
      key="nos-solutions"
      offset="-90"
      className={classes.ResNavLinks}
    >
      Nos Solutions
    </AnchorLink>,
    <AnchorLink
      href="#etude-de-cas"
      key="etude-de-cas"
      offset="-70"
      className={classes.ResNavLinks}
    >
      Étude de Cas
    </AnchorLink>,

    <AnchorLink
      href="#a-propos"
      key="a-propos"
      offset="350"
      className={classes.ResNavLinks}
    >
      À propos
    </AnchorLink>,
  ];

  const anchor = "right"; // Specify the anchor as "right"

  return (
    <div>
      <React.Fragment key={anchor}>
        <MenuIcon
          onClick={toggleDrawer(anchor, true)}
          className={classes.Menu}
        />

        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          className={classes.Drawer}
        >
          <Box className={classes.NavbarList}>
            <CloseIcon
              onClick={toggleDrawer(anchor, false)}
              className={classes.NavCloseBtn}
            />

            {navItems.map((item, index) => (
              <Box
                key={index}
                variant="h6"
                className={classes.ResponsiveListItemText}
                onClick={toggleDrawer(anchor, false)}
              >
                {item}
              </Box>
            ))}

            <AnchorLink
              onClick={toggleDrawer(anchor, false)}
              href="#contactez-nous"
              key="contactez-nous"
              offset="30"
              className={classes.ResposiveContactUsButtons}
            >
              <Buttons
                Title="Contactez-nous"
                className={classes.ContactUsButtons}
              />
            </AnchorLink>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default ResponsiveNavbar;
