// Importing necessary dependencies
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const DETECTION_OPTIONS = {
  order: ["cookie", "navigator"],
  caches: ["cookie"],
};

// use(initReactI18next) will bind react-i18next to the i18n instance
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: DETECTION_OPTIONS,
    fallbackLng: "en-US", // fallback language
    //lng: 'en-US', // default language
    resources: {
      // JSON files with the translations
      "en-US": {
        translations: require("./locales/en/translations.json"),
      },
      "fr-FR": {
        translations: require("./locales/fr/translations.json"),
      },
    },
    ns: ["translations"], // the namespace
    defaultNS: "translations", // default namespace :- namespaces are keys from the resources object
  });

i18n.languages = ["en-US", "fr-FR"];

export default i18n;
