import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  Button: {
    textDecoration: "none",
    "&.MuiButtonBase-root": {
      backgroundColor: "#49A995",
      color: "#FFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: 14,
      fontWeight: 600,
      textTransform: "capitalize",
      boxShadow: "9px 7px 7px 0px rgba(0, 0, 0, 0.18)",
      borderRadius: 6,
      textDecoration: "none",
      "&:hover": {
        backgroundColor: "#025b62ff",
      },
    },
  },
}));
