import React from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { useStyles } from "./ButtonsStyle";
import clsx from "clsx";

export default function BasicButtons({ Title, className }) {
  const classes = useStyles();
  return (
    <Box className={classes.ButtonContainer}>
      <Button variant="contained" className={clsx(classes.Button, className)}>
        {Title}
      </Button>
    </Box>
  );
}
