import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useStyles } from "./OurSloutionsStyle";
import DescriptionModal from "./DescriptionModal";

const Boxes = ({ data }) => {
  const classes = useStyles();

  const [openModals, setOpenModals] = useState(
    new Array(data.length).fill(false)
  );

  const openModal = (index) => {
    const updatedOpenModals = [...openModals];
    updatedOpenModals[index] = true;
    setOpenModals(updatedOpenModals);
  };

  const closeModal = (index) => {
    const updatedOpenModals = [...openModals];
    updatedOpenModals[index] = false;
    setOpenModals(updatedOpenModals);
  };

  return (
    <>
      {data.map((item, index) => (
        <div data-aos="zoom-out" data-aos-delay="200">
          <Box key={index} className={classes.TransportationContainer}>
            <Box onClick={() => openModal(index)} style={{ cursor: "pointer" }}>
              <img
                className={classes.TransportationImg}
                src={item.img}
                alt=""
              />
            </Box>

            {item.text !== "Résolution de conflit dans le “Workflow" ? (
              <Typography className={classes.TransportationTitle}>
                {item.text}
              </Typography>
            ) : (
              <Typography className={classes.TransportationTitle}>
                Résolution de <br /> conflit dans le <br /> “Workflow"
              </Typography>
            )}
            <DescriptionModal
              open={openModals[index]}
              onClose={() => closeModal(index)}
              data={item}
            />
          </Box>
        </div>
      ))}
    </>
  );
};

export default Boxes;
