import React from "react";
import { useStyles } from "./Meet&TalkStyle";
import { Box } from "@mui/material";
import MeetTeam from "./MeetTeam";
import LetsMeet from "./LetsMeet";

const MeetTalk = () => {
  const classes = useStyles();
  return (
    <Box className={classes.MeetTeamContainer} id="léquipe">
      <MeetTeam />
      <LetsMeet />
    </Box>
  );
};

export default MeetTalk;
