import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./StudyCasesStyle";

const StudyCaseCard = ({ index, data, pdfName }) => {
  const classes = useStyles();

  const openPDF = () => {
    const newTab = window.open(pdfName, "_blank");
  };

  return (
    <div data-aos="zoom-in-down" data-aos-delay="100">
      <Box
        key={index}
        className={
          index === 1 || index === 4
            ? classes.StudyCasesCardContainerOne
            : classes.StudyCasesCardContainer
        }
      >
        <Box className={classes.TiltContainer}>
          <img
            className={
              index === 1 || index === 4
                ? classes.CenterTiltedImage
                : classes.TiltedImage
            }
            alt=""
            src={data.img}
          />
          <Box
            className={
              index === 1 || index === 4 ? classes.CenterBorder : classes.Border
            }
          ></Box>
        </Box>
        <Typography className={classes.AboutCard}>
          {data.description}{" "}
        </Typography>
        <Button variant="text" className={classes.SavoirBtn} onClick={openPDF}>
          En savoir plus
        </Button>
      </Box>
    </div>
  );
};

export default StudyCaseCard;
