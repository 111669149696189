import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    backgroundImage: `url("/ProjectCostBG.png")`,
    width: "100%",
    height: "663px",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginTop: "-78px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ProjectCostContentContainer: {
    textAlign: "center",
  },

  ProjectCostHeading: {
    "&.MuiTypography-root": {
      // textTransform: "capitalize",
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: 40,
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: 1.7,
      letterSpacing: "-1.68px",

      "@media (max-width: 780px)": {
        fontSize: 35,
        lineHeight: "41px",
      },
    },
  },

  ProjectCostSpan: {
    color: "#49A995",
    letterSpacing: "-1.68px",
    fontSize: "40px",
    fontStyle: "italic",
    textTransform: "lowercase",
    fontWeight: 600,
  },

  ProjectCostButton: {
    textDecoration: "none",
    "&.MuiButtonBase-root": {
      marginTop: 64,
      backgroundColor: "#49A995",
      padding: "17px 27px",
      boxShadow: " 9px 7px 7px 0px rgba(0, 0, 0, 0.)",
      borderRadius: "9px",
      color: "#FFF",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      fontFamily: "'Poppins', sans-serif",
      "&:hover": {
        backgroundColor: "#025b62ff",
      },
    },
  },
}));
