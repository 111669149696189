import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    height: "auto",
    backgroundColor: "#1F3052",
    padding: "50px 97px",
    marginTop: "-4.5rem",

    "@media(max-width:600px)": {
      marginLeft: "-50px",
    },
  },
  FooterLogoText: {
    "&.MuiTypography-root": {
      color: "#FFF",
      fontFamily: "'Poppins', sans-serif",
      fontSize: 12,
      fontWeight: 400,
      marginBottom: 15,
    },
  },
  FooterLogo: {
    marginBottom: 12,
  },
  FooterWrapper: {
    marginTop: "4rem",
    display: "flex",
    justifyContent: "space-between",
  },

  FooterLogoText: {
    "&.MuiTypography-root": {
      color: "#FFF",
      fontFamily: "'Poppins', sans-serif",
      fontSize: 12,
      fontWeight: 400,
      marginTop: 12,
      width: 236,
    },
  },
  ListItem: {
    "& .MuiTypography-root": {
      color: "#FFF",
      fontFamily: "'Poppins', sans-serif",
      fontSize: 16,
      fontWeight: 400,
      "&:hover": {
        color: "#49A995",
      },
    },
  },
  ListItemWrapper: {
    "&.MuiListItem-root": {
      padding: 0,
    },
  },

  ContactNumber: {
    textDecoration: "none",
    "& .MuiTypography-root": {
      color: "#49A995",
      fontFamily: "'Poppins', sans-serif",
      fontSize: 24,
      fontWeight: 500,
    },
  },
  EmailText: {
    textDecoration: "none",
    color: "#FFF",
    fontFamily: "'Poppins', sans-serif",
    fontSize: 16,
    transition: "color 0.2s, text-decoration 0.2s",
    "&:hover": {
      color: "#007acc", // Change to a different color on hover
      textDecoration: "underline", // Add underline on hover
    },
  },
  Heading: {
    "&.MuiTypography-root": {
      color: "#FFF",
      fontFamily: "'Poppins', sans-serif",
      fontSize: 24,
      fontWeight: 500,
    },
  },

  Input: {
    "&.MuiTextField-root": {
      width: "100%",
      color: "#FFF",
    },
    "& .MuiInputBase-root": {
      height: 54,
      fontFamily: "'Inter', sans-serif",
      fontSize: 16,
      borderRadius: 6,
      color: "#FFF",
      fontWeight: 400,
      width: "273px",
      "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
        {
          borderColor: "transparent !important",
        },

      "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
        borderColor: "white !important",
      },
      "& :-webkit-autofill": {
        height: 13,
        "-webkit-box-shadow": "0 0 0 100px #F2F3F7 inset",
        "-webkit-text-fill-color": "black",
      },
      "& .MuiSvgIcon-root": {
        fill: "red",
        fontSize: 35,
      },

      "@media (max-width: 1340px)": {
        width: "100%",
        fontSize: "12px",
      },
    },
  },

  InputLable: {
    "&.MuiFormLabel-root": {
      color: "#FFF",
      fontFamily: "'Poppins', sans-serif",
      fontSize: 14,
      fontWeight: 400,
    },
  },

  VotreEmail: {
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },

  CopyWriteContainer: {
    height: "40px",
    width: "100%",
    borderTop: "1px solid #FFF",
    display: "flex",
    marginTop: "34px",
    alignItems: "center",
    justifyContent: "space-between",

    "@media (max-width: 705px)": {
      flexDirection: "column",
      paddingTop: "10px",
    },
  },

  CopyWrite: {
    "&.MuiTypography-root": {
      color: "#FFF",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      textAlign: "center",
      lineHeight: "normal",

      "@media (max-width: 705px)": {
        flexDirection: "column",
      },
    },
  },

  FooterLink: {
    color: "#FFF",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    cursor: "pointer",
    textDecoration: "none",

    "@media (max-width: 705px)": {
      paddingTop: "10px",
    },

    "@media (max-width: 441px)": {
      paddingTop: "10px",
    },
  },

  FooterMainContainer: {
    marginTop: "5rem",
  },

  bgMeettlak: {
    height: "73px",
  },
  NavLinks: {
    textDecoration: "none",
    cursor: "pointer",
  },
  SendMessageBtn: {
    "&.MuiButtonBase-root": {
      height: 41,
      // width: 180,
      fontFamily: "'Inter', sans-serif",
      fontSize: 14,
      borderRadius: 6,
      backgroundColor: "#49A995",
      color: "#FFF",
      fontWeight: 600,
      textTransform: "capitalize",
      marginTop: 15,
      "&:hover": {
        backgroundColor: "#49A995",
        boxShadow: "9px 7px 7px 0px rgba(0, 0, 0, 0.18)",
      },
    },
  },

  CircularProgress: {
    display: "flex",
    alignItems: "center",
    paddingTop: "12px",
    paddingLeft: "13px",
  },
  Loader: {
    "& .MuiCircularProgress-svg": {
      color: "#49A995",
    },
  },
}));
