import React from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./HomePageHeaderStyle";
import Buttons from "../Buttons/Buttons";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import AnchorLink from "react-anchor-link-smooth-scroll";
import ResponsiveNavbar from "./ResposiveNavbar";

const HomePageHeader = () => {
  const classes = useStyles();

  const handleLogoHover = () => {
    window.location.reload();
  };

  const navItems = [
    <AnchorLink
      href="#accueil"
      key="accueil"
      offset="100"
      className={classes.NavLinks}
    >
      Accueil
    </AnchorLink>,
    <AnchorLink
      href="#vos-activites"
      key="vos-activites"
      offset="-390"
      className={classes.NavLinks}
    >
      Vos Activités
    </AnchorLink>,
    <AnchorLink
      href="#nos-solutions"
      key="nos-solutions"
      offset="20"
      className={classes.NavLinks}
    >
      Nos Solutions
    </AnchorLink>,
    <AnchorLink
      href="#etude-de-cas"
      key="etude-de-cas"
      offset="-10"
      className={classes.NavLinks}
    >
      Étude de Cas
    </AnchorLink>,

    <AnchorLink
      href="#a-propos"
      key="a-propos"
      offset="350"
      className={classes.NavLinks}
    >
      À propos
    </AnchorLink>,
  ];

  return (
    <Box className={classes.HeaderMainContainer}>
      <div data-aos="zoom-in-down" data-aos-delay="500">
        <img
          src="Sonabimlogo.svg"
          alt=""
          onMouseEnter={handleLogoHover}
          className={classes.WebsiteLogo}
        />
      </div>
      <Box className={classes.HeaderSubWrapper}>
        <div data-aos="zoom-in-left" data-aos-delay="500">
          <Box className={classes.ListWrapper}>
            {navItems.map((item, index) => (
              <Typography
                key={index}
                variant="h6"
                className={classes.ListItemText}
              >
                {item}
              </Typography>
            ))}
          </Box>
        </div>
        <div data-aos="zoom-in-left" data-aos-delay="500">
          <AnchorLink
            href="#contactez-nous"
            key="contactez-nous"
            offset="140"
            className={classes.NavContactUsButtons}
          >
            <Buttons
              Title="Contactez-nous"
              className={classes.ContactUsButton}
            />
          </AnchorLink>
        </div>
        <div data-aos="zoom-in-left" data-aos-delay="500">
          <LanguageDropdown />
        </div>

        <Box className={classes.ResponsiveNavbar}>
          <ResponsiveNavbar />
        </Box>
      </Box>
    </Box>
  );
};

export default HomePageHeader;
