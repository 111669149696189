import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    height: "auto",
    background: "linear-gradient(179deg, #1F3052 27.67%, #49A995 149.13%)",
    padding: "1px 1px",

    "@media (max-width: 625px)": {
      fontSize: 55,
    },
  },
  PlumbingBg: {
    backgroundImage: `url("/ActivityBG.png")`,
    backgroundPosition: "center",
    height: 434,
    backgroundRepeat: "no-repeat",
    marginTop: 106,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "@media (max-width: 1000px)": {
      backgroundSize: "100% auto",
      marginTop: 0,
    },

    "@media (max-width: 915px)": {
      backgroundImage: `url("/ActivityBG.pn")`,
      backgroundColor: "#49A995",
      height: "auto",
    },
  },

  PlumbingTextContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",

    "@media (max-width: 780px)": {
      flexDirection: "column",
    },
  },
  PlumbingMainHeading: {
    "&.MuiTypography-root": {
      color: "#1F3052",
      fontSize: 40,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
      lineHeight: "107%",
      letterSpacing: "-1.6px",
      width: 353,
      textAlign: "center",

      "@media (max-width: 780px)": {
        marginTop: "15px",
        marginBottom: "15px",
      },
    },
  },
  span: {
    display: "block",
  },

  TransformonsSpan: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
  EnSpan: {
    fontSize: 40,
    color: "#FFF",
    fontWeight: 500,
  },
  performanceSpan: {
    fontSize: 40,
    color: "#FFF",
    fontWeight: 500,
  },

  colorWhite: {
    color: "#FFF",
    fontWeight: 500,
  },
  Divider: {
    width: 1,
    height: 215,
    backgroundColor: "black",
    marginLeft: 24,
    marginRight: 32,

    "@media (max-width: 780px)": {
      height: 1,
      width: "97%",
    },
  },
  AboutPlumbing: {
    "&.MuiTypography-root": {
      color: "#1F3052",
      fontSize: 14,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400,
      lineHeight: "normal",
      letterSpacing: " -0.56px",
      width: 371,

      "@media (max-width: 780px)": {
        marginTop: "15px",
        marginBottom: "30px",
        textAlign: "center",
      },
    },
  },
  AboutPlumbingSpan: {
    display: "block",
    marginTop: 20,
  },
  AboutWrapper: {
    height: 210,
  },
  ReadMoreButton: {
    "&.MuiButtonBase-root": {
      padding: 0,
      textTransform: "capitalize",
      color: "#1F3052",
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400,
      marginTop: 15,
    },
  },
  YourActivityMainWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 77.71,
    marginBottom: "11rem",
  },
  YourActivityWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    "@media (max-width: 850px)": {
      width: "95%",
      margin: "auto",
      overflowX: "scroll",
      scrollbarWidth: "thin",
      scrollbarColor: "rgba(0, 0, 0, 0.5) transparent",
      "&::-webkit-scrollbar": {
        height: 6,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgba(0, 0, 0, 0.5)",
        height: "1px !impotant",
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
    },
  },

  YourActivityContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0rem 2rem",
  },
  YourActivityContainerComplexe: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0.5rem 2rem",
  },
  YourActivitiesTitle: {
    "&.MuiTypography-root": {
      color: "#FFF",
      fontSize: 64,
      fontFamily: "'Poppins', sans-serif",
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: "93%",
      letterSpacing: " -1.28px",
      marginBottom: 45,

      "@media (max-width: 570px)": {
        fontSize: 55,
      },
    },
  },
  YourActivitiesSpan: {
    color: "#49A995",
    fontSize: "84px",
    fontFamily: "'Poppins', sans-serif",
    fontStyle: "italic",
    fontWeight: 600,
    lineHeight: "93%",
    letterSpacing: " -1.68px",
    "@media (max-width: 570px)": {
      fontSize: "60px",
    },
    "@media (max-width: 405px)": {
      fontSize: "50px",
    },
  },
  YourActivityHeading: {
    "&.MuiTypography-root": {
      color: "#FFF",
      fontSize: 24,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
      marginTop: 24,
      textAlign: "center",
    },
  },
  YourActivityPara: {
    "&.MuiTypography-root": {
      color: "#FFF",
      fontSize: 14,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 400,
      lineHeight: "normal",
      letterSpacing: "-0.56px",
      textAlign: "center",
      marginTop: 8,
      width: "auto",
    },
  },
  savoirBtn: {
    "&.MuiButtonBase-root": {
      color: "#49A995",
      fontFamily: "'Poppins', sans-serif",
      fontSize: 14,
      textTransform: "lowercase",
      marginTop: "1rem",
      borderRadius: 6,
      position: "relative",
      zIndex: 1,
      "&:hover": {
        backgroundColor: "#49A995",
        color: "white",
      },
    },
  },
}));
