import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useStyles } from "./ProjectCostStyle";
import AnchorLink from "react-anchor-link-smooth-scroll";

const ProjectCost = () => {
  const classes = useStyles();

  const emailAddress = "contact@sonabim.com";
  const emailSubject = "Je souhaites être contacter par l'équipe de SONABIM";

  const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
    emailSubject
  )}`;

  return (
    <Box className={classes.MainContainer}>
      <Box className={classes.ProjectCostContentContainer}>
        <Typography className={classes.ProjectCostHeading}>
          <div data-aos="fade-right">
            Souhaitez-vous améliorer la transparence de votre projet
            <br />
          </div>
          <div data-aos="fade-left">
            tout en{" "}
            <span className={classes.ProjectCostSpan}>
              réduisant ses coûts ?
            </span>
          </div>
        </Typography>
        <div data-aos="fade-up">
          <AnchorLink
            href="#contactez-nous"
            key="contactez-nous"
            offset="30"
            className={classes.ProjectCostButton}
          >
            <Button className={classes.ProjectCostButton} href={mailtoLink}>
              Contactez-nous
            </Button>
          </AnchorLink>
        </div>
      </Box>
    </Box>
  );
};

export default ProjectCost;
