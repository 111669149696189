import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  MeetTeamContainer: {
    background: "linear-gradient(179deg, #1F3052 27.67%, #49A995 149.13%)",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "5rem",

    "@media(max-width: 525px)": {
      gap: "9rem",
    },
  },

  MeetTeamBg: {
    backgroundImage: `url("/MeetTeamBg.png")`,
    minHeight: "745.563px",
    // height: "100%",
    width: "100%",
    backgroundSize: "95% 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    position: "relative",
    left: 34,
    "@media (max-width: 1440px)": {
      backgroundSize: "95% 95%",
    },

    "@media (max-width: 1270px)": {
      backgroundSize: "86% 80%",
    },

    "@media (max-width: 1177px)": {
      backgroundImage: `url("/ActivityBG.png")`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    "@media (max-width: 1121px)": {
      left: "0px",
      backgroundSize: "97% 80%",
    },

    "@media (max-width: 591px)": {
      backgroundSize: "95% 74%",
    },

    "@media (max-width: 590px)": {
      backgroundImage: "none",
      background: "#49A995",
      borderRadius: "50px",
      backgroundSize: "0%",
      minHeight: "0px",
      width: "90%",
      padding: "25px 0px 25px 0px",
      margin: "-13px auto auto auto",
      marginBottom: "4rem",
    },

    "@media (max-width: 458px)": {
      padding: "48px 1px  1px  1px",
    },
  },
  TextContainer: {
    height: 451,
    width: 593,

    justifyContent: "end",
    flexDirection: "column",
    display: "flex",
    "@media (max-width: 1177px)": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "80%",
      gap: "30px",
    },

    "@media (max-width: 879px)": {
      flexDirection: "column",
      gap: "  0px",

      height: "auto",
    },
  },
  MeetMainHeadnig: {
    "&.MuiTypography-root": {
      color: "#FFF",
      fontFamily: "'Poppins', sans-serif",
      fontSize: 86,
      fontWeight: 400,
      lineHeight: "92%",
      letterSpacing: "-4.469px",
      "@media (max-width: 1270px)": {
        fontSize: 81,
      },

      "@media (max-width: 1000px)": {
        fontSize: 65,
      },

      "@media (max-width: 939px)": {
        fontSize: 55,
      },
      // textAlign: "center",
    },
  },
  MeetMainHeadnigSpan: {
    color: "#1F3052",
    fontFamily: "'Poppins', sans-serif",
    fontSize: 111,
    fontStyle: "italic",
    fontWeight: 700,
    lineHeight: "92%",
    letterSpacing: "-4.469px",

    "@media (max-width: 1270px)": {
      fontSize: 89,
    },

    "@media (max-width: 1020px)": {
      fontSize: 75,
    },

    "@media (max-width: 939px)": {
      fontSize: 65,
    },
  },
  AboutMeetTeaam: {
    "&.MuiTypography-root": {
      color: "#1F3052",
      fontFamily: "'Inter', sans-serif",
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "normal",
      letterSpacing: "-0.894px",
      margin: "38px 0px",
      // textAlign: "center",
      width: "428px",

      "@media (max-width: 525px)": {
        width: "auto",
        textAlign: "center",
        color: "#FFF",
        fontSize: "16px !important",
      },
    },
  },
  AboutMeetTeaamSpan: {
    display: "block",
    marginTop: 15,
  },
  DiscoverBtn: {
    "&.MuiButtonBase-root": {
      color: "#FFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: 22,
      fontWeight: 600,
      lineHeight: "normal",
      borderRadius: 9,
      borderColor: "#FFF",
      width: 181,
      height: 81,
      textTransform: "capitalize",
    },
  },
  LetsMeetBG: {
    backgroundImage: `url("/LetsTalkBG.png")`,
    minHeight: "807px",
    height: "100%",
    width: "100%",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "space-around",
    // alignItems: "center",

    "@media (max-width: 590px)": {
      backgroundSize: "140% 100%",
    },

    "@media (max-width: 888px)": {
      marginTop: "-8rem",
    },
  },

  LetsMeetHeading: {
    "&.MuiTypography-root": {
      color: "#1F3052",
      fontFamily: "'Poppins', sans-serif",
      fontSize: 64,
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: "93%",
      letterSpacing: "-1.28px",
      marginBottom: 16,
      textAlign: "center",

      "@media (max-width: 752px)": {
        fontSize: 56,
      },
    },
  },
  LetsMeetHeadingSpan: {
    color: "#49A995",
    fontFamily: "'Poppins', sans-serif",
    fontSize: 84,
    fontStyle: "italic",
    fontWeight: 600,
    lineHeight: "93%",
    letterSpacing: "-1.68px",
    "@media (max-width: 752px)": {
      fontSize: 75,
    },

    "@media (max-width: 502px)": {
      fontSize: 67,
    },
  },
  LetsMeetTextContainer: {
    marginTop: "7rem",
    marginLeft: "7rem",
    width: 800,
    margin: "8rem",

    "@media (max-width: 600px)": {
      marginLeft: "4rem",
      marginBottom: "8rem",
      width: "auto",
      margin: "0rem",
      marginTop: "8rem",
    },

    "@media (max-width: 494px)": {
      marginLeft: "0rem",
      width: "90%",
    },
  },
  LetsMeetAbout: {
    "&.MuiTypography-root": {
      color: "#1F3052",
      fontFamily: "'Poppins', sans-serif",
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "-0.96px",
      // textAlign: "center",
      marginBottom: 34,

      "@media (max-width: 454px)": {
        textAlign: "center",
      },
    },
  },
  FirstRowInputs: {
    display: "flex",
    marginBottom: 24,

    "@media (max-width: 720px)": {
      flexDirection: "column",
      gap: "15px",
    },
  },

  Input: {
    "&.MuiTextField-root": {
      width: "100%",
    },
    "& .MuiInputBase-root": {
      height: 54,
      fontFamily: "'Inter', sans-serif",
      fontSize: 16,
      borderRadius: 6,
      backgroundColor: "#FFF",
      color: "#354363",
      fontWeight: 400,
      marginRight: 24,
      "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
        {
          borderColor: "transparent !important",
        },

      "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
        borderColor: "#8A8A8A !important",
      },
      "& :-webkit-autofill": {
        height: 13,
        "-webkit-box-shadow": "0 0 0 100px #F2F3F7 inset",
        "-webkit-text-fill-color": "black",
      },
      "& .MuiSvgIcon-root": {
        fill: "red",
        fontSize: 35,
      },
    },
  },

  MessageInput: {
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  SendMessageBtn: {
    "&.MuiButtonBase-root": {
      height: 51,
      // width: 180,
      fontFamily: "'Inter', sans-serif",
      fontSize: 14,
      borderRadius: 6,
      backgroundColor: "#49A995",
      color: "#FFF",
      fontWeight: 600,
      textTransform: "capitalize",
      marginTop: 24,
      "&:hover": {
        backgroundColor: "#49A995",
        boxShadow: "9px 7px 7px 0px rgba(0, 0, 0, 0.18)",
      },
    },
  },
  LetsMeetspan: {
    color: "#49A995",
  },
  ContactLetsMeetspan: {
    color: "#49A995",
    cursor: "pointer",
    textDecoration: "none",
    transition: "color 0.2s, text-decoration 0.2s",
    "&:hover": {
      color: "#007acc", // Change to a different color on hover
      textDecoration: "underline", // Add underline on hover
    },
  },
  ButtonLoader: {
    display: "flex",
    alignItems: "center",
  },

  CircularProgress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "25px",
    marginLeft: "15px",
  },
  Loader: {
    "& .MuiCircularProgress-svg": {
      color: "#49A995",
    },
  },

  CheckboxContaainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "-10px",
  },
  cheackboxTypography: {
    "&.MuiTypography-root": {
      color: "gray",
      fontFamily: "'Poppins', sans-serif",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "-0.96px",
    },
  },

  ConditionSpan: {
    fontSize: "16px",
    color: "gray",
    fontWeight: 500,
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      color: "#49A995",
    },
  },

  FormControl: {
    "& .MuiFormHelperText-root": {
      marginLeft: 3,
    },
  },
  errorText: {
    "&.MuiTypography-root": {
      color: "#d32f2f",
      fontSize: "0.75rem",
    },
  },
}));
