import React from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./ActivitesSectionStyle";
import Plumbing from "./Plumbing";
import YourActivity from "./YourActivity";

const ActivitesSection = () => {
  const classes = useStyles();
  return (
    <Box className={classes.MainContainer} id="vos-activites">
      <Plumbing />
      <Box className={classes.YourActivityMainWrapper}>
        <div data-aos="zoom-in">
          <Typography className={classes.YourActivitiesTitle}>
            Votre <span className={classes.YourActivitiesSpan}>Activité</span>
          </Typography>
        </div>
        <Box className={classes.YourActivityWrapper}>
          <div data-aos="flip-left">
            <YourActivity
              Title="Transport & Mobilité"
              src="TransportationIcon.svg"
              about="Vous souhaitez améliorer l'efficacité de vos réseaux de transport, "
              subText=" accroître la satisfaction de vos passagers et renforcer la sécurité de votre réseau. Explorez notre expertise dans le développement de systèmes de transport intelligents, intégrant le jumeaux numérique et des applications innovantes. Contactez-nous dès maintenant"
            />
          </div>
          <div data-aos="flip-left">
            <YourActivity
              Title="Energie"
              src="EnergyIcon.svg"
              about="Vous cherchez à renforcer la rentabilité de votre infrastructure énergétique ?"
              subText=" Découvrez comment l'utilisation organisée des données des jumeaux numériques et l'intégration de solutions technologiques innovantes offrent la possibilité de résoudre de manière tangible les défis quotidiens, contribuant ainsi à l'amélioration globale de la structure. Contactez-nous"
            />
          </div>
          <div data-aos="flip-left">
            <YourActivity
              Title="Bâtiment Technique"
              src="ComplexIIndustrycon.svg"
              about="Vous désirez réduire les coûts opérationnels et optimiser la performance énergétique de votre infrastructure ? "
              subText=" Explorez comment le jumeau numérique peut conduire à des économies substantielles sur la maintenance des infrastructures, tout en améliorant l'efficacité opérationnelle et la durabilité de vos installations. Contactez-nous dès maintenant"
            />
          </div>
          <div data-aos="flip-left">
            <YourActivity
              Title="Complexe Industrie"
              src="ComplexIcon.svg"
              about="Vous souhaitez optimiser l'efficacité de votre processus de production ?"
              subText=" Explorez les bénéfices concrets résultant de l'exploitation méthodique des données des jumeaux numériques et de l'intégration de solutions technologiques novatrices. Celles-ci ouvrent la voie à une résolution tangible des défis quotidiens, favorisant ainsi l'amélioration continue de vos processus et de la performance globale de votre entreprise. Contactez-nous dès maintenant"
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default ActivitesSection;
