const Array = [
  {
    Heading: "Conditions générales",
    paragraphy: [
      "Vous êtes actuellement connecté au site Web public de SONABIM. Les informations et contenus qui y sont publiés sont soumis aux conditions générales suivantes et votre accès au présent site Web, ainsi que votre utilisation de ce site, valent acceptation de l’ensemble des dites conditions générales.",
      "Le but de ce site web est de présenter la structure de SONABIM, ses services ainsi que ses informations financières. Malgré les efforts de SONABIM il ne peut être garanti que ce site soit exempt d’inexactitudes, d’erreurs typographiques ou d’omissions.",
      "Le site SONABIM contient des liens vers d’autres sites web par souci de praticité d’usage. Le contenu de ces sites ne relève pas du contrôle de SONABIM et n’implique aucune responsabilité ni approbation de la part de SONABIM. Le site web de SONABIM et son contenu est protégé par les droits de propriété intellectuelle et est la propriété exclusive de Hello Pomelo.",
      "Les présentes conditions générales sont régies par la législation française et doivent être interprétées selon ladite législation. Toute action en justice à l’encontre de SONABIM doit être engagée en France.",
    ],
  },

  {
    Heading: "Données Personnelles",
    paragraphy: [
      "SONABIM s’engage à assurer le meilleur niveau de protection à vos données personnelles en conformité avec la loi Informatique et Libertés modifiée en France et avec le règlement (UE) 2016/679 du Parlement européen et du Conseil. Vos données personnelles sont ainsi collectées et traitées dans le respect du cadre légal applicable. Pour toute information sur la protection des données personnelles, vous pouvez consulter le site de la Commission Nationale de l’Informatique et des Libertés.  ",
    ],
  },

  {
    Heading: "Politique de protection de données personnelles de SONABIM",
    paragraphy: [
      "L’entreprise a mis en œuvre une politique relative à la protection des données à caractère personnel en adéquation avec les principes issus du cadre légal : licéité, transparence et loyauté du traitement, finalité explicite et légitime, pertinence et proportionnalité des données collectées, durée de conservation des données limitée, sécurité et confidentialité des données et respect des droits des personnes.",
    ],
  },

  {
    Heading: "Finalité – Comment sont traitées vos données personnelles",
    paragraphy: [
      "Les données personnelles que vous nous communiquez sont traitées par l’équipe Marketing de SONABIM en tant que Responsable du Traitement dans le cadre d’un usage strictement interne. Elles sont utilisées dans le but de vous contacter pour de prochains évènements organisés par SONABIM, pour vous adresser des publications ou des sollicitations commerciales.",
      ,
      "Concernant les démarches d’information et de prospection dans un cadre B2B, ce traitement se fonde sur l’Intérêt Légitime de SONABIM. Ceci, dans la mesure où ces démarches permettent de proposer à nos clients et prospects des prestations efficaces et pertinentes vis-à-vis de leur activité commerciale.",
      ,
      "Concernant les démarches d’information et de prospection commerciale dans un cadre B2C, ce traitement se fonde sur le consentement des Personnes Concernées. Dans ce cas, les Personnes Concernées ont explicitement donné leur accord pour être démarchées au moment de la collecte de leurs données personnelles (opt-in).",
    ],
  },

  {
    Heading: "Stockage et durée de conservation",
    paragraphy: [
      "Les données personnelles traitées font l’objet d’une conservation limitée. Elles sont conservées pendant les trois années suivant leur collecte ou suivant le dernier contact avec la personne concernée. Les données sont stockées sur le territoire des Etats membres de l’Union Européenne par notre prestataire Ionos et ne sont transmises à aucun tiers.",
    ],
  },

  {
    Heading: "Vos droits",
    paragraphy: [
      "Conformément à la réglementation, vous disposez d’un droit d’accès, de rectification et d’effacement sur vos données personnelles. Vous pouvez également vous opposer au traitement des données vous concernant. A cet effet, chaque communication électronique est accompagnée d’un lien de désabonnement.",
      "Autrement, vous pouvez exercer ces droits à tout moment et directement en adressant un courrier électronique à contact@sonabim ou en envoyant un courrier postal à l’attention du Délégué à la Protection des Données à l’adresse suivante :",
      { tips: ["SONABIM", "8 rue Mayence", "44100 Nantes", "France"] },
      "Votre demande doit préciser votre nom, prénom ainsi qu’une adresse permettant de correspondre avec vous. La demande doit être accompagnée d’un justificatif d’identité. Votre demande sera traitée dans un délai maximum d’un mois après réception. Toutefois, si ce délai venait à être prolongé, en raison de la complexité de votre demande ou du nombre important de demandes à traiter, vous en serez informé(e) dans les plus brefs délais.",
      ,
      "SONABIM apporte une grande attention aux données à caractère personnel qui lui sont confiées. Néanmoins, si vous considérez que le traitement des données vous concernant porte atteinte à vos droits, vous disposez de la faculté d’introduire une réclamation auprès de la CNIL.",
    ],
  },

  {
    Heading: "Politique d’utilisation des Cookies",
    paragraphy: [
      "Lors de la consultation de notre site, des cookies sont déposés sur votre ordinateur, votre mobile ou votre tablette. Un cookie est un fichier texte déposé dans votre navigateur lors de la visite d’un site. Ces fichiers ont pour but de vous faciliter la navigation et de vous adresser des services adaptés.Si vous ne souhaitez pas utiliser de cookies, vous devez configurer les paramètres de votre ordinateur afin d’effacer tous les cookies de sites Web et/ou recevoir une notification si des cookies sont stockés. Si vous ne souhaitez pas modifier la configuration des cookies, poursuivez simplement votre visite sur le site. Pour en savoir plus sur les cookies et leur incidence sur vous et votre expérience de navigation, consultez le site de la CNIL.",
    ],
  },

  {
    Heading: "Quel type de cookies utilisons-nous ?",

    points: [
      {
        point: "Les cookies de session",
        paragraphy:
          "Les « Cookies de Session » restent stockés dans votre navigateur seulement durant votre session de navigation, c’est à dire jusqu’à ce que vous quittiez le site. Dans le cadre du site hello-pomelo.com, ils nous servent à mémoriser la langue d’utilisation du site.",
      },
      {
        point: "Les cookies de mesure d’audience",
        paragraphy:
          "Ces cookies sont des fichiers texte contenant des données anonymes. Ils servent à établir des statistiques et volumes de fréquentation et d’utilisation des divers éléments composant le site. Ils permettent à Hello Pomelo d’améliorer la qualité du site. Ces cookies sont gérés par Google via ses solutions Analytics et Tag Manager.",
      },
      {
        point: "‍Cookies provenant des sites internet tiers",
        paragraphy:
          "Notre site contient des liens renvoyant vers d’autres sites qui utilisent des cookies. Pour en savoir plus sur ces derniers, veuillez consulter les informations fournies par ces sites tiers sur leurs propres cookies.",
      },
    ],
  },

  {
    Heading: "Combien de temps sont conservés les cookies ?",
    paragraphy: [
      "Les cookies déposés lors de votre visite du site peuvent être conservés, soit le temps de votre passage sur ces derniers soit, au maximum, pour une durée de 13 mois.",
    ],
  },

  {
    Heading: "Comment gérer les cookies sur les navigateurs ?",
    paragraphy: [
      "Les cookies peuvent être gérés de différentes manières : en fonction de vos préférences, vous pouvez les autoriser, les refuser, les désactiver (un cookie déjà déposé peut seulement être désactivé, celui-ci s’effacera seulement au terme de sa durée de vie). Nous attirons votre attention sur le fait que le rejet total des cookies peut conduire à rendre certains de nos Services inaccessibles ou entraîner une utilisation moins performante, moins efficace ou moins adaptée de nos Services.",
      "Vous pouvez exprimer vos choix et modifier vos souhaits par l’intermédiaire de votre navigateur :",
      {
        tips: ["Microsoft Edge", "Safari", "Firefox", "Chrome", "Opera"],
      },
      "Si vous utilisez différents terminaux, assurez-vous de paramétrer le navigateur correspondant selon vos préférences.",
    ],
  },
];

export default Array;
