import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./LanguageDropdownStyle";

const LanguageDropdown = () => {
  const classes = useStyles();

  const [selectedLanguage, setSelectedLanguage] = useState("FR");
  const [dropdown, setDropdown] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  const selectLanguage = (language) => {
    setSelectedLanguage(language);
    setDropdown(false);
  };

  const handleMouseEnter = (language) => {
    setHoveredOption(language);
  };

  const handleMouseLeave = () => {
    setHoveredOption(null);
  };

  const getSrcIcon = (language) => {
    if (selectedLanguage === language || hoveredOption === language) {
      return "LanguageHoverIcon.svg";
    } else {
      return "LanguageDefaultIcon.svg";
    }
  };

  return (
    <Box className={classes.LanguageWrapper}>
      <Box className={classes.MainContainer} onClick={() => toggleDropdown()}>
        <img
          src={getSrcIcon(selectedLanguage)}
          height="24px"
          width="24px"
          alt=""
        />
        <Typography variant="h6" className={classes.LanguageText}>
          {selectedLanguage}
        </Typography>
      </Box>
      {/* {dropdown && (
        <div
          className={classes.dropdownCss}
          style={{
            position: "absolute",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {selectedLanguage === "EN" ? (
            <div
              key="FR"
              className={classes.MainContainer}
              onClick={() => selectLanguage("FR")}
              onMouseEnter={() => handleMouseEnter("FR")}
              onMouseLeave={handleMouseLeave}
            >
              <img src={getSrcIcon("FR")} height="24px" width="24px" alt="" />
              <Typography variant="h6" className={classes.LanguageText}>
                FR
              </Typography>
            </div>
          ) : (
            <div
              key="EN"
              className={classes.MainContainerEN}
              onClick={() => selectLanguage("FR")}
              onMouseEnter={() => handleMouseEnter("FR")}
              onMouseLeave={handleMouseLeave}
            >
              <img src={getSrcIcon("EN")} height="24px" width="24px" alt="" />
              <Typography variant="h6" className={classes.LanguageText}>
                EN
              </Typography>
            </div>
          )}
        </div>
      )} */}
    </Box>
  );
};

export default LanguageDropdown;
