import "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBjM5H2ayEIMrI2RrE1CrKmAWID1BVhR4c",
  authDomain: "sonabimwebsite.firebaseapp.com",
  projectId: "sonabimwebsite",
  storageBucket: "sonabimwebsite.appspot.com",
  messagingSenderId: "427965959528",
  appId: "1:427965959528:web:e536ccc012087ff0805add"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
logEvent(analytics, 'firebase_config');

export { db };
