import React from "react";
import Slider from "react-slick";
import { useStyles } from "./TheyTrustStyle";
import { Box } from "@mui/material";

export default function SimpleSlider() {
  const classes = useStyles();
  var settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    autoplay: true, // Enable auto-play
    autoplaySpeed: 1500, // Set auto-play speed in milliseconds
    slidesToShow: 5,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 4,
        },
      },

      {
        breakpoint: 870,
        settings: {
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const CardsData = [
    "Alterea-blue.png",
    "Eiffage_logo.svg.png",
    "Fleury-Michon.png",
    "LVMH.png",
    "NOVAM.png",
    "Oteis.jpg",
    "Poma.png",
    "Suez_logo.png",
    "Zénith-nantes-metropole.png",
  ];

  return (
    <Box className={classes.SliderContainer}>
      <Slider {...settings} className={classes.Slider}>
        {CardsData.map((item, index) => (
          <div key={index} className={classes.Test}>
            <img alt="" src={item} height="50px" width="100px" />
          </div>
        ))}
      </Slider>
    </Box>
  );
}
