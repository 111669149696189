import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { Stack } from "@mui/system";
import { useStyles } from "./Meet&TalkStyle";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import clsx from "clsx";

export default function Input({
  className,
  placeholder,
  multiline,
  onChange,
  value,
  label,
  error,
  onBlur,
  ...props
}) {
  const classes = useStyles();

  return (
    <Stack sx={{ width: "100%" }}>
      <InputLabel
        shrink
        htmlFor="bootstrap-input"
        className={classes.PasswordLable}
      >
        {label}
      </InputLabel>
      <FormControl className={classes.FormControl}>
        <TextField
          {...props}
          id="outlined-basic"
          type="text"
          rows={5}
          error={!!error}
          helperText={error || ""}
          value={value}
          onBlur={onBlur}
          className={clsx(classes.Input, className)}
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
          }}
          placeholder={placeholder}
          multiline={multiline}
          onChange={onChange}
        />
      </FormControl>
    </Stack>
  );
}
