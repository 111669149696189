import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import { Stack } from "@mui/system";
import { useStyles } from "./HomePageStyleFooter";
import { Box, Button, ListItem, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { db } from "../../firebase";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

import {
  addDoc,
  collection,
  FieldValue,
  serverTimestamp,
} from "firebase/firestore";
import MessageSnackbar from "../Snackbar/Snackbar";

export default function FooterInput() {
  const [Loader, setLoader] = useState(false);

  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState("");
  const [OpenSnackBar, setOpenSnackBar] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const SendEmail = async () => {
    try {
      const docRef = await addDoc(collection(db, "subscribers"), {
        email: inputValue,
        timestamp: serverTimestamp(),
      });
      console.log(
        "Subscriber added successfully with ID: " + docRef.id,
        inputValue
      );
    } catch (error) {
      console.log("Error adding subscriber: " + error.message);
    }
  };

  const handleSendEmail = async () => {
    if (!inputValue) {
      console.error("Input value is empty. API not called.");
      return;
    }

    setLoader(true);
    const endpoint = "https://sendemail-takoka2nxq-uc.a.run.app";

    const data = {
      toEmail: "contact@sonabim.com",
      fromEmail: "contact@sonabim.com",
      fromName: "Sonabim",
      subjectText: "Newsletter",
      messageText: inputValue,
    };

    try {
      const response = await axios.post(endpoint, data);
      if (response.status === 200) {
        console.log("Form submitted successfully", inputValue);
        setInputValue("");
        setOpenSnackBar(true);
        setLoader(false);
      }
    } catch (error) {
      console.error("Form submission failed:", error);
    }
  };

  return (
    <Stack sx={{ width: "100%", marginTop: "18px" }}>
      <InputLabel
        shrink
        htmlFor="bootstrap-input"
        className={classes.InputLable}
      >
        Votre Email
      </InputLabel>
      <FormControl>
        <TextField
          id="outlined-basic"
          type="text"
          placeholder="votrenom@emaildetravail.com"
          value={inputValue}
          onChange={handleInputChange}
          className={classes.Input}
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
          }}
        />
      </FormControl>

      <ListItem className={classes.ListItemWrapper}>
        <Button
          variant="contained"
          className={classes.SendMessageBtn}
          onClick={() => {
            SendEmail();
            handleSendEmail();
          }}
        >
          Envoyez
        </Button>

        {Loader && (
          <Box className={classes.CircularProgress}>
            <CircularProgress className={classes.Loader} />
          </Box>
        )}
      </ListItem>
      <MessageSnackbar
        OpenSnackBar={OpenSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        Title="Votre demande a été envoyé "
      />
    </Stack>
  );
}
