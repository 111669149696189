import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    background: "linear-gradient(179deg, #1F3052 27.67%, #49A995 149.13%)",
    height: "auto",
    marginTop: "-11.6rem",
    padding: "160px 80px",
    "@media (max-width: 1440px)": {
      padding: "160px 25px",
    },
  },
  StudyCasesText: {
    "&.MuiTypography-root": {
      fontSize: "64px",
      fontStyle: "italic",
      fontWeight: "400",
      fontFamily: "'Poppins', sans-serif",
      color: "#FFF",
      lineHeight: "93%",
      textAlign: "center",
    },
  },
  StudyCasesCardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    "@media (max-width: 410px)": {
      paddingLeft: "0px",
    },
  },
  StudyCasesCardContainerOne: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "1.8rem",
    justifyContent: "flex-start",
  },
  StudyCasesSpan: {
    color: "#49A995",
    fontSize: "84px",
    fontStyle: "italic",
    fontWeight: "600",
    fontFamily: "'Poppins', sans-serif",
    lineHeight: "93%",
  },

  TiltContainer: {
    perspective: "600px",
    width: "409.196px",
    height: "275.647px",
    marginTop: "2.5rem",
    marginBottom: "2.5rem",
    "@media (max-width: 1440px)": {
      width: "389.196px",
      height: "256.647px",
    },
    "@media (max-width: 405px)": {
      width: "370.196px",
      height: "250.647px",
    },
    "@media (max-width: 382px)": {
      marginLeft: "8px",
    },
  },
  TiltedImage: {
    width: "100%",
    height: "100%",
    transform: "rotateY(18deg) rotateX(6deg)",
    borderRadius: "22% 28% 28% 22%",
    objectFit: "cover",

    "@media (max-width: 405px)": {
      width: "103%",
      marginLeft: "0px",
    },

    "@media (max-width: 430px)": {
      marginLeft: "5px",
    },

    "@media (max-width: 395px)": {
      width: "100%",
      height: "98%",
      paddingLeft: "7px",
    },

    "@media (max-width: 382px)": {
      width: "97%",
      height: "95%",
    },
  },
  CenterTiltedImage: {
    width: "100%",
    height: "100%",
    transform: "rotateY(-10deg) rotateX(6deg)",
    borderRadius: "24% 22% 22% 24%",
  },
  Border: {
    width: "404.586px",
    height: "271.022px",
    content: '""',
    position: "absolute",
    top: "-14px",
    left: "10px",
    right: "0",
    bottom: "0",
    border: "3px solid #35A999",
    borderRadius: "23% 29% 24% 20%",
    transform: "rotateY(16deg) rotateX(6deg)",
    "@media (max-width: 1440px)": {
      width: "381.586px",
      height: "256.022px",
    },

    "@media (max-width: 395px)": {
      width: "370.586px",
      height: "248.022px",
    },

    "@media (max-width: 382px)": {
      width: "360.586px",
      height: "240.022px",
      marginRight: "13px",
    },
  },

  CenterBorder: {
    width: "411.586px",
    height: "271.022px",
    content: '""',
    position: "absolute",
    top: "-13px",
    left: "-18px",
    right: "0",
    bottom: "0",
    border: "3px solid #35A999",
    transform: "rotateY(-10deg) rotateX(6deg)",
    borderRadius: "24% 20% 22% 24%",
    "@media (max-width: 1440px)": {
      top: "-11px",
      left: "-15px",
      width: "389.586px",
      height: "257.022px",
    },
  },
  AboutCard: {
    "&.MuiTypography-root": {
      color: "#FFF",
      fontFamily: "'Poppins', sans-serif",
      fontSize: 24,
      fontWeight: 700,
      width: "345px",
      textAlign: "center",
    },
  },
  StudyCaseCardWrapper: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "52px",
    flexWrap: "wrap",
  },
  ExploreMoreButtonWraapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ExploreMoreButton: {
    textDecoration: "none",
    "&.MuiButtonBase-root": {
      textTransform: "capitalize",
      borderColor: "#FFF",
      borderRadius: 6,
      fontFamily: "'Inter', sans-serif",
      fontSize: 14,
      fontWeight: 600,
      color: "#FFF",
      height: 51,
      width: 144,
      marginTop: 52,
      "&:hover": {
        border: "none",
        backgroundColor: "#025b62ff",
      },
    },
  },
  SavoirBtn: {
    "&.MuiButtonBase-root": {
      textTransform: "capitalize",
      color: "#49A995",
      fontFamily: "Poppins",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      marginTop: "1rem",
      borderRadius: 6,
      zIndex: "1",
      "&:hover": {
        color: "white",
        backgroundColor: "#49A995",
      },
    },
  },
}));
