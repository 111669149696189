import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    backgroundImage: `url("/OurSolutionsBg.png")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    height: "auto",
    marginTop: "-10rem",
    display: "flex",
    justifyContent: "center",
    backgroundPosition: "center",
    position: "relative",
    // zIndex: 9999,
    "@media (max-width: 465px)": {
      backgroundImage: "unset",
      height: "auto",
    },
  },

  HeadingBox: {
    marginTop: "8rem",
    textAlign: "center",
    width: "80%",
    marginBottom: "8rem",
    "@media (max-width: 1282px)": {
      width: "86%",
    },

    "@media (max-width: 1184px)": {
      width: "93%",
    },

    "@media (max-width: 1050px)": {
      width: "97%",
    },
    "@media (max-width: 465px)": {
      marginTop: "16rem",
    },
  },

  TitleSolutionHeading: {
    "&.MuiTypography-root": {
      fontSize: "64px",
      fontStyle: "italic",
      fontWeight: "400",
      fontFamily: "'Poppins', sans-serif",
      color: "#1F3052",

      "@media (max-width: 1032px)": {
        marginTop: "40px",
      },

      "@media (max-width: 560px)": {
        fontSize: "51px",
      },

      "@media (max-width: 440px)": {
        lineHeight: "59px",
      },

      // marginBottom: 0,
    },
  },

  TitleSolutionHeadingSpan: {
    color: "#49A995",
    fontSize: "84px",
    fontStyle: "italic",
    // fontFamily: "Poppins",
    fontWeight: "600",
    fontFamily: "'Poppins', sans-serif",

    "@media (max-width: 560px)": {
      fontSize: "67px",
    },
  },

  HeadingBoxParagraphy: {
    "&.MuiTypography-root": {
      color: "#1F3052",
      fontSize: "24px",
      fontWeight: "500",
      fontFamily: "Poppins",
      lineSpacing: "-0.96px",
      lineHeight: "normal",
      fontStyle: "normal",
      letterSpacing: "-0.96px",
      "@media (max-width: 1032px)": {
        marginTop: "5px",
      },
      "@media (max-width: 440px)": {
        marginTop: "15px",
      },
    },
  },

  TransportationMainContainer: {
    width: "100%",
    marginTop: "52px !important",
    margin: "auto",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    rowGap: "42px",
    height: "auto",
    marginBottom: "2rem",
    "@media (max-width: 1030px)": {
      width: "97%",
      gridTemplateColumns: "1fr 1fr 1fr ",
    },

    "@media (max-width: 760px)": {
      width: "97%",
      gridTemplateColumns: "1fr 1fr ",
    },

    "@media (max-width: 460px)": {
      width: "97%",
      gridTemplateColumns: "1fr ",
    },
    "@media (max-width: 465px)": {
      marginBottom: "6rem",
    },
  },

  TransportationContainer: {
    height: "200px",
    backgroundColor: "white",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    padding: "25px 0px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "rgba(73, 169, 147, 0.7)",
    },
  },

  TransportationTitle: {
    "&.MuiTypography-root": {
      fontSize: "24px",
      color: "#1F3052",
      fontWeight: "600",
      marginTop: "0px",
      fontFamily: "Inter",
    },
  },

  TransportationImg: {
    objectFit: "contain",
    width: "122.649px",
    height: "104.451px",
  },
  modalBg: {
    height: "480.586px",
    margin: "auto",
    backgroundImage: `url("/ActivityBG.png")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    marginTop: 106,
    backgroundSize: "93% 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "@media (max-width : 1010px)": {
      borderRadius: "9px",
      backgroundImage: "none",
      backgroundColor: "#49A995",
      height: "auto",
      padding: "55px 20px 55px 20px ",
      margin: " 1rem  2rem 1rem 2rem",
    },
  },

  ModalContainer: {
    display: "flex",
    width: "75%",

    gap: "32px",

    "@media (max-width : 1010px)": {
      width: "100%",
    },

    "@media (max-width : 730px)": {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  ModalHeading: {
    width: "50%",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "1px solid black",

    "@media (max-width : 730px)": {
      borderBottom: "none",
      borderRight: "none",
      width: "90%",
    },
  },

  ModalParagraphy: {
    width: "50%",

    "@media (max-width : 730px)": {
      textAlign: "unset",
      width: "90%",
    },
  },

  ModalTitle: {
    "&.MuiTypography-root": {
      color: "#1F3052",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: 700,
      letterSpacing: "-1.6px",
      "@media (max-width : 805px)": {
        fontSize: "34px",
      },

      "@media (max-width : 650px)": {
        fontSize: "28px",
      },
    },
  },

  TextButton: {
    textDecoration: "none",
    "&.MuiButtonBase-root": {
      color: "#1F3052",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
      letterSpacing: "-0.56px",
      padding: "0px",
      marginTop: "20px",
      "&:hover": {
        color: "#025b62ff",
      },
    },
  },

  Modaaltext: {
    "&.MuiTypography-root": {
      color: "#1F3052",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      letterSpacing: "-0.56px",

      "@media (max-width : 1010px)": {
        color: "#FFF",
      },
    },
  },

  subDescription: {
    "&.MuiTypography-root": {
      marginTop: "30px",
      color: "#1F3052",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      letterSpacing: "-0.56px",

      "@media (max-width : 1010px)": {
        color: "#FFF",
      },
    },
  },

  orderList: {
    marginTop: "2px",
    color: "#1F3052",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "-0.56px",

    "@media (max-width : 1010px)": {
      color: "#FFF",
    },
  },
}));
