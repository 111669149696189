import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useStyles } from "./Meet&TalkStyle";

const MeetTeam = () => {
  const classes = useStyles();
  return (
    <Box className={classes.MeetTeamBg}>
      <Box className={classes.TextContainer}>
        <div data-aos="zoom-in-up">
          <Typography className={classes.MeetMainHeadnig}>
            L’ <span className={classes.MeetMainHeadnigSpan}>équipe</span>
          </Typography>
          <Typography className={classes.AboutMeetTeaam}>
            Chez Sonabim, chaque membre occupe une place fondamentale et
            contribue à l'enrichissement collectif. Nous encourageons un
            environnement de travail qui célèbre la diversité individuelle, la
            respecte, et la met en valeur.
            <br />
            <br />
            Nous sommes profondément convaincus du développement complet du
            potentiel de chacun, ainsi que de la mise en lumière des talents et
            des atouts de chaque collaborateur.
            <br />
            <br />
            Cependant, nous croyons fermement que, au-delà du talent, la clé du
            succès au sein de Sonabim réside dans l'alignement sur nos valeurs
            essentielles et notre approche de travail
          </Typography>
        </div>
      </Box>
      <Box></Box>
    </Box>
  );
};

export default MeetTeam;
