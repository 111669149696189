import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useStyles } from "./ActivitesSectionStyle";
import clsx from "clsx";

const Plumbing = () => {
  const classes = useStyles();

  return (
    <Box className={classes.PlumbingMainContainer} id="a-propos">
      <Box className={classes.PlumbingBg}>
        <div className={classes.PlumbingTextContainer}>
          <div data-aos="fade-right">
            <Typography className={classes.PlumbingMainHeading}>
              Nous<span className={classes.TransformonsSpan}>transformons</span>{" "}
              <span className={classes.VosSpan}>
                vos données <span className={classes.EnSpan}>en</span>
              </span>{" "}
              <span className={classes.performanceSpan}>performance grâce</span>{" "}
              au BIM
            </Typography>
          </div>

          <Box className={classes.Divider}></Box>
          <Box className={classes.AboutWrapper}>
            <div data-aos="fade-left">
              <Typography className={classes.AboutPlumbing}>
                Nous travaillons de manière proactive à optimiser les processus
                de nos clients en utilisant des solutions numériques de pointe.
                Notre approche consiste à identifier et éliminer les
                inefficacités, puis à développer et mettre en place des
                technologies et des automatisations personnalisées pour garantir
                une rentabilité accrue.
                <span className={classes.AboutPlumbingSpan}>
                  Nous vous accompagnons dans l'optimisation de la gestion des
                  bâtiments, de votre système de production, et de vos actifs,
                  en mettant l'accent sur l'agilité et la flexibilité
                </span>
              </Typography>
            </div>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default Plumbing;
