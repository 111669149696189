import React from "react";
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { useStyles } from "./HomePageStyleFooter";
import FooterInput from "./FooterInput";
import CopywriteFooter from "./CopywriteFooter";
import AnchorLink from "react-anchor-link-smooth-scroll";

const HomePageFooter = () => {
  const classes = useStyles();

  const email = "contact@sonabim.com";
  const emailSubject = "Demande d'information";

  return (
    <>
      <img src="bgMeettlak.png" width="103%" className={classes.bgMeettlak} />
      <Box className={classes.MainContainer}>
        <Box className={classes.FooterMainContainer}>
          <div data-aos="zoom-in-up">
            <Grid container spacing={2} className={classes.FooterWrapper}>
              <Grid item xs={12} sm={6} md={4}>
                <img src="FooterLogo copy.svg" alt="" />
                <Typography className={classes.FooterLogoText}>
                  Nous sommes les alchimistes du BIM, nous organisons les
                  systèmes de données en solutions innovantes pour l'énergie, le
                  transport & mobilité, et l'industrie complexe.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6" className={classes.Heading}>
                  Liens
                </Typography>
                <List>
                  <ListItem className={classes.ListItemWrapper}>
                    <AnchorLink
                      href="#accueil"
                      key="accueil"
                      offset="100"
                      className={classes.NavLinks}
                    >
                      <ListItemText
                        className={classes.ListItem}
                        primary="Accueil"
                      />
                    </AnchorLink>
                  </ListItem>
                  <ListItem className={classes.ListItemWrapper}>
                    <AnchorLink
                      href="#vos-activites"
                      key="vos-activites"
                      offset="-180"
                      className={classes.NavLinks}
                    >
                      <ListItemText
                        className={classes.ListItem}
                        primary="Votre Activité"
                      />
                    </AnchorLink>
                  </ListItem>
                  <ListItem className={classes.ListItemWrapper}>
                    <AnchorLink
                      href="#nos-solutions"
                      key="nos-solutions"
                      offset="-90"
                      className={classes.NavLinks}
                    >
                      <ListItemText
                        className={classes.ListItem}
                        primary="Nos Solutions"
                      />
                    </AnchorLink>
                  </ListItem>
                  <ListItem className={classes.ListItemWrapper}>
                    <AnchorLink
                      href="#etude-de-cas"
                      key="etude-de-cas"
                      offset="-70"
                      className={classes.NavLinks}
                    >
                      <ListItemText
                        className={classes.ListItem}
                        primary="Étude de Cas"
                      />
                    </AnchorLink>
                  </ListItem>
                  <ListItem className={classes.ListItemWrapper}>
                    <AnchorLink
                      href="#léquipe"
                      key="léquipe"
                      offset="100"
                      className={classes.NavLinks}
                    >
                      <ListItemText
                        className={classes.ListItem}
                        primary="Équipe"
                      />
                    </AnchorLink>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6" className={classes.Heading}>
                  Contact
                </Typography>
                <List>
                  <ListItem className={classes.ListItemWrapper}>
                    <a
                      href={`mailto:${email}?subject=${encodeURIComponent(
                        emailSubject
                      )}`}
                      className={classes.EmailText}
                    >
                      {email}
                    </a>
                  </ListItem>
                  <ListItem className={classes.ListItemWrapper}>
                    <ListItemText
                      className={classes.ContactNumber}
                      primary="09.81.67.44.94"
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <Typography variant="h6" className={classes.Heading}>
                  Newsletter
                </Typography>
                <ListItem className={classes.ListItemWrapper}>
                  <FooterInput />
                </ListItem>
              </Grid>
            </Grid>
          </div>
        </Box>
        <CopywriteFooter />
      </Box>
    </>
  );
};

export default HomePageFooter;
