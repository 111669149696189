import React, { useEffect, useState } from "react";

import { useStyles } from "./ConditionsStyle";
import { Box, Button, Typography } from "@mui/material";
import data from "./Data";
import PagesData from "./PagesData";
import { Link, useNavigate } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

const Conditions = () => {
  const navigate = useNavigate();

  const Arraydata = data;

  const email = "contact@sonabim.com";
  const emailSubject = "Demande d'information";

  const classes = useStyles();

  const BackToHome = () => {
    navigate("/");
  };

  const [isVisible, setIsVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollY = window.scrollY;

    if (scrollY > 500) {
      setIsVisible(true);
      setIsScrolled(true);
    } else {
      setIsVisible(false);
      setIsScrolled(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box className={classes.Container}>
      <Button className={classes.BackButton} onClick={() => BackToHome()}>
        <ArrowBackIosNewRoundedIcon />
      </Button>
      <Box className={classes.MainContainer}>
        <Box className={classes.HeadingContainer}>
          <Typography variant="h2" className={classes.Heading}>
            Conditions Générales
          </Typography>
          <Typography className={classes.SonabinTitle}>
            SONABIM{" "}
            <span className={classes.SonabinTitleSpan}>
              {" "}
              by SONA-EXPERIENCE{" "}
            </span>{" "}
          </Typography>
        </Box>

        <Box className={classes.ContectInfoContainer}>
          <Typography className={classes.PointParagraphy}>
            Société par actionssimplifiée au capital de 35 000 €
          </Typography>
          <Typography className={classes.PointParagraphy}>
            RCS Nantes sous le numéro B808 439 269
          </Typography>
          <Typography className={classes.PointParagraphy}>
            Siège social/HQ: 8 rue Mayence, Nantes, France
          </Typography>
          <Typography className={classes.PointParagraphy}>
            Email:{" "}
            <a
              className={classes.paraEmail}
              href={`mailto:${email}?subject=${encodeURIComponent(
                emailSubject
              )}`}
            >
              {email}
            </a>
          </Typography>
          <Typography className={classes.telephone}>
            Tel: <span className={classes.plus}>+</span>
            <span className={classes.Number}>33 9 81 67 44 94</span>
          </Typography>
          <Typography className={classes.PointParagraphy}>
            Hébergement/ISP : Azure{" "}
          </Typography>
        </Box>

        <PagesData />

        <Button
          className={`${classes.UpButton} ${isVisible ? "visible" : "hidden"}`}
          onClick={scrollToTop}
          style={{ display: isScrolled ? "flex" : "none" }}
        >
          <ArrowUpwardIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default Conditions;
