import React from "react";
import { useStyles } from "./HomePageStyleFooter";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const FooterInput = () => {
  const classes = useStyles();

  const conditionsGeneralesLink =
    "https://docs.google.com/document/d/1HDHInXou8KnKPWPimOvoezjzns7sLETbZD3xR1B4M4U/edit#heading=h.19b1eg6qbzjw";

  return (
    <Box className={classes.CopyWriteContainer}>
      <Typography className={classes.CopyWrite}>
        Copyright © 2023 Tous droits réservés - Sonabim
      </Typography>

      <Link className={classes.FooterLink} to="/Condition">
        Conditions Générales
      </Link>
    </Box>
  );
};

export default FooterInput;
