import React, { useState } from "react";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import { useStyles } from "./Meet&TalkStyle";
import Input from "./Input";
import axios from "axios";
import { Form, Formik } from "formik";
import MessageSnackbar from "../Snackbar/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import * as Yup from "yup";

const LetsTalkForm = () => {
  const classes = useStyles();

  const [Loader, setLoader] = useState(false);

  const [OpenSnackBar, setOpenSnackBar] = useState(false);

  const handleFormSubmit = async (values, { resetForm }) => {
    setLoader(true);
    const endpoint = "https://sendemail-takoka2nxq-uc.a.run.app";

    if (!values.VotreNom && !values.VotreEmail && !values.VotreProjet) {
      console.error("All input fields are empty. Form not submitted.");
      setLoader(false);
      return;
    }

    const Message = values.VotreProjet;
    const fullName = values.VotreNom;
    const fullNameArray = fullName.split(" ");
    const firstName = fullNameArray[0];
    const lastName = fullNameArray.slice(1).join(" ");

    const messageText = `Prénom: ${firstName}\nNom: ${lastName}\nEmail: ${values.VotreEmail}\nMessage: ${Message}`;

    const data = {
      toEmail: "contact@sonabim.com",
      fromEmail: "contact@sonabim.com",
      fromName: "Sonabim",
      subjectText: "Contactez nous",
      messageText: messageText,
    };

    try {
      const response = await axios.post(endpoint, data);

      if (response.status === 200) {
        console.log("Form submitted successfully");
        setLoader(false);
        setOpenSnackBar(true);
        resetForm({
          VotreNom: "",
          VotreEmail: "",
          VotreProjet: "",
          ConditionsCheckbox: false,
        });
      }
    } catch (error) {
      console.error("Form submission failed:", error);
    }
  };

  const validationSchema = Yup.object().shape({
    VotreNom: Yup.string().required("Veuillez indiquer votre nom"),
    VotreEmail: Yup.string()
      .email("Email invalide")
      .required(
        "Veuillez fournir une adresse électronique au format approprié"
      ),

    VotreProjet: Yup.string().required("Votre Projet est requis"),
    ConditionsCheckbox: Yup.boolean().oneOf(
      [true],
      "Veuillez accepter les conditions générales"
    ),
  });

  return (
    <>
      <Formik
        initialValues={{
          VotreNom: "",
          VotreEmail: "",
          VotreProjet: "",
          ConditionsCheckbox: false,
        }}
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, handleChange, errors, touched, values }) => (
          <Form>
            <Box className={classes.LetsTalkFormContainer}>
              <div data-aos="fade-right">
                <Box className={classes.FirstRowInputs}>
                  <Input
                    placeholder="Prénom et Nom de Famille"
                    label="Votre Nom"
                    name="VotreNom"
                    value={values.VotreNom}
                    onChange={handleChange}
                    error={touched.VotreNom ? errors.VotreNom : ""}
                  />
                  <Input
                    label="Votre Email"
                    placeholder="nom@emailtravail.com"
                    name="VotreEmail"
                    value={values.VotreEmail}
                    onChange={handleChange}
                    error={touched.VotreEmail ? errors.VotreEmail : ""}
                  />
                </Box>
              </div>
              <div data-aos="fade-left">
                <Input
                  name="VotreProjet"
                  placeholder="Votre Message"
                  label="Votre Projet"
                  value={values.VotreProjet}
                  multiline={true}
                  className={classes.MessageInput}
                  onChange={handleChange}
                  error={touched.VotreProjet ? errors.VotreProjet : ""}
                />
              </div>
              <div className={classes.CheckboxContaainer}>
                <Checkbox
                  name="ConditionsCheckbox"
                  onChange={handleChange}
                  value={values.ConditionsCheckbox}
                  checked={values.ConditionsCheckbox}
                  error={
                    touched.ConditionsCheckbox ? errors.ConditionsCheckbox : ""
                  }
                  sx={{
                    color: "#49A995",
                    "&.Mui-checked": {
                      color: "#49A995",
                    },
                  }}
                />
                <Typography className={classes.cheackboxTypography}>
                  En soumettant ce formulaire, vous confirmez avoir lu et
                  accepté{" "}
                  <Link to="/Condition" className={classes.ConditionSpan}>
                    nous Conditions générales
                  </Link>
                </Typography>{" "}
              </div>
              {touched.ConditionsCheckbox && errors.ConditionsCheckbox && (
                <Typography className={classes.errorText}>
                  {errors.ConditionsCheckbox}
                </Typography>
              )}
              <div data-aos="fade-right">
                <Box className={classes.ButtonLoader}>
                  <Button
                    variant="contained"
                    className={classes.SendMessageBtn}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Envoyez votre message
                  </Button>
                  {Loader && (
                    <Box className={classes.CircularProgress}>
                      <CircularProgress className={classes.Loader} />
                    </Box>
                  )}
                </Box>
              </div>
            </Box>
          </Form>
        )}
      </Formik>
      <MessageSnackbar
        OpenSnackBar={OpenSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        Title="Votre Message a été envoyé "
      />
    </>
  );
};

export default LetsTalkForm;
