import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  LanguageWrapper: {
    cursor: "pointer",
    // backgroundColor: "#1F3052",
    // height: 100,
  },
  MainContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: 25,
    width: 50,

    "@media(max-width: 1100px)": {
      marginBottom: "10px",
    },
  },

  MainContainerEN: {
    display: "flex",
    alignItems: "center",
    marginLeft: 25,
    width: 50,
    marginBottom: 10,
    opacity: "0.5",
  },

  LanguageText: {
    "&.MuiTypography-root": {
      color: "#FFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: 12,
      fontWeight: 400,
      marginLeft: 5,
    },

    dropdown: {
      display: "flex",
    },
  },
}));
