import React from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./TheyTrustStyle";

export default function TestimonialsContainer({ Testimoaials, Name, company }) {
  const classes = useStyles();
  return (
    <Box className={classes.testimonialsContainer} id="témoignages">
      <Box className={classes.HeadingText}>
        <img className={classes.quotes} alt="" src="quotes.png" />
        <Typography className={classes.Paragraphy}>{Testimoaials}</Typography>
        <img className={classes.TestimonialsImg} src="Line.png" alt="" />

        <Typography className={classes.JessyaInn}>
          {company} <br />
          <span className={classes.JessyaInnSpan}>{Name}</span>
        </Typography>
      </Box>
    </Box>
  );
}
