import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  SnackBarWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  Alert: {
    "&.MuiPaper-root": {
      backgroundColor: "#49A995",
      boxShadow: "none",
      borderRadius: 0,
      color: "white",
      fontSize: 16,
      padding: "5px 15px 5px 0px",
    },
  },
  SnackBar: {
    "&.MuiSnackbar-root": {
      // top: 53,
    },
    "& .MuiPaper-root": {
      backgroundColor: "#49A995",
      width: "100%",
      minWidth: 0,
      "& .MuiAlert-icon": {
        display: "none",
      },
      "& .MuiSnackbarContent-action": {
        marginLeft: 0,
        paddingLeft: 0,
      },
    },
  },

  ModalTitle: {
    "&.MuiTypography-root": {
      color: "white",
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 500,
      fontSize: 24,
    },
  },
  DialogContent: {
    "&.MuiDialogContent-root": {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#377378",
    },
  },
  CloseIcon: {
    marginLeft: 20,
    fontSize: 27,
    cursor: "pointer",
    color: "#1F3052",
  },
}));
