import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useStyles } from "./OurSloutionsStyle";
import AnchorLink from "react-anchor-link-smooth-scroll";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "transparent",
  outline: "none",
  // boxShadow: 24,
  p: 4,
  border: "none",
  width: "100%",
};

export default function BasicModal({ open, onClose, data }) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} onClick={() => onClose()}>
          <Box className={classes.modalBg}>
            <Box className={classes.ModalContainer}>
              <Box className={classes.ModalHeading}>
                <Typography className={classes.ModalTitle}>
                  {data.text}
                </Typography>
              </Box>

              <Box className={classes.ModalParagraphy}>
                <Typography className={classes.Modaaltext}>
                  {data.description}
                </Typography>

                <ol className={classes.orderList}>
                  {data.points?.map((item, index) => {
                    return (
                      <li key={index} className={classes.ListText}>
                        {item}
                      </li>
                    );
                  })}
                </ol>

                {data.subDescription && (
                  <Typography className={classes.subDescription}>
                    {data.subDescription}
                  </Typography>
                )}
                <AnchorLink
                  href="#contactez-nous"
                  key="contactez-nous"
                  offset="140"
                  className={classes.TextButton}
                >
                  <Button className={classes.TextButton} variant="text">
                    Contactez-nous
                  </Button>
                </AnchorLink>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
