import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  HomePageMainContainer: {
    width: "100%",
    overflowX: "hidden",
    maxWidth: "2560px",
    margin: "auto",
    width: "100%",
  },
}));
