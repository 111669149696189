import React from "react";
import { Box } from "@mui/material";
import HomePageHeader from "../../Components/HomePageHeader/HomePageHeader";
import ImageCarousel from "../../Components/ImageCarousel/ImageCarousel";
import ActivitesSection from "../../Components/ActivitesSection/ActivitesSection";
import OurSloutions from "../../Components/OurSloutions/OurSloutions";
import StudyCases from "../../Components/StudyCases/StudyCases";
import ProjectCost from "../../Components/ProjectCost/ProjectCost";
import TheyTrustUS from "../../Components/TheyTrustUs/TheyTrustus";
import HomePageFooter from "../../Components/HomePageFooter/HomePageFooter";
import MeetTalk from "../../Components/Meet&Talk/Meet&Talk";
import { useStyles } from "./HomePageStyle";

const HomePage = () => {
  const classes = useStyles();
  return (
    <Box className={classes.HomePageMainContainer} id="accueil">
      <HomePageHeader />
      <ImageCarousel />
      <ActivitesSection />
      <OurSloutions />
      <StudyCases />
      <ProjectCost />
      <TheyTrustUS />
      <MeetTalk />
      <HomePageFooter />
    </Box>
  );
};

export default HomePage;
