import { useStyles } from "./ConditionsStyle";
import { Box, Typography } from "@mui/material";
import data from "./Data";
import React from "react";

const PagesData = () => {
  const Arraydata = data;
  const classes = useStyles();

  return (
    <Box>
      {Arraydata.map((item, index) => (
        <div key={index}>
          <Typography variant="h5" className={classes.HeadingText}>
            {item.Heading}
          </Typography>

          <Box>
            {Array.isArray(item.paragraphy) &&
              item.paragraphy.map((para, paraIndex) => (
                <Box key={paraIndex} className={classes.Paragraphy}>
                  {typeof para === "object" && para.tips ? (
                    <ul className={classes.UnorderList}>
                      {para.tips.map((tip, tipIndex) => (
                        <li key={tipIndex}>{tip}</li>
                      ))}
                    </ul>
                  ) : (
                    <Typography
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      {para}
                    </Typography>
                  )}
                </Box>
              ))}
          </Box>

          <Box>
            {Array.isArray(item.points) &&
              item.points.map((point, pointIndex) => (
                <Box key={pointIndex}>
                  <Typography className={classes.point}>
                    {point.point}
                  </Typography>
                  <Typography className={classes.PointParagraphy}>
                    {point.paragraphy}
                  </Typography>
                </Box>
              ))}
          </Box>
        </div>
      ))}
    </Box>
  );
};

export default PagesData;
