import React from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./Meet&TalkStyle";
import LetsTalkForm from "./LetsTalkForm";

const LetsMeet = () => {
  const classes = useStyles();

  const email = "contact@sonabim.com";
  const emailSubject = "Je souhaites être contacter par l'équipe de SONABIM ";

  return (
    <Box className={classes.LetsMeetBG} id="contactez-nous">
      <Box className={classes.LetsMeetTextContainer}>
        <div data-aos="flip-down" data-aos-delay="100">
          <Typography className={classes.LetsMeetHeading}>
            Contactez <span className={classes.LetsMeetHeadingSpan}>nous</span>
          </Typography>
        </div>
        <div data-aos="fade-left" data-aos-delay="100">
          <Typography className={classes.LetsMeetAbout}>
            Vous souhaitez améliorer les performances de votre flux de travail ?
            Réduire vos coûts ? contactez-nous au{" "}
            <span className={classes.LetsMeetspan}>09.81.67.44.94</span> ou{" "}
            <a
              href={`mailto:${email}?subject=${encodeURIComponent(
                emailSubject
              )}`}
              className={classes.ContactLetsMeetspan}
            >
              {email}
            </a>
          </Typography>
        </div>
        <LetsTalkForm />
      </Box>
    </Box>
  );
};

export default LetsMeet;
