import React from "react";
import { useStyles } from "./TheyTrustStyle";
import { Box, Button, Typography } from "@mui/material";
import TrustUsCarousel from "./TrustUsCarousel";
import Testimonials from "./TestimonialsContainer";

const TheyTrustUS = () => {
  const classes = useStyles();
  return (
    <Box className={classes.MainContainer}>
      <div data-aos="fade-down">
        <Typography className={classes.Heading}>
          Ils nous <br className={classes.lineBreaker} />{" "}
          <span className={classes.HeadingSpan}>font confiance </span>
        </Typography>
      </div>
      <TrustUsCarousel />
      <div data-aos="fade-up">
        <Typography className={classes.testimonials}>Témoignages</Typography>
      </div>

      <Box className={classes.TestimonialsMainContainer}>
        <div data-aos="zoom-in-right" data-aos-delay="200">
          <Testimonials
            Testimoaials="Sonabim nous accompagne dans tous nos projets, nous réalisons des relevés de précisions d’ouvrages ferroviaires comme des ponts Eiffel, des gares, des passages sous voie. L’équipe Sonabim possède un savoir faire qui permet de trouver la réponse technique adaptée en toute occasion. La montée en compétence autour des outils BIM avec SONABIM nous permet de répondre à de nouveaux marchés spécialisés."
            Name="Géxia Rail / Directeur"
            company="Vincent MARTINACHE"
          />
        </div>
        <div data-aos="zoom-in-left" data-aos-delay="200">
          <Testimonials
            Testimoaials="Notre collaboration avec Sonabim nous donne l’occasion de codévelopper de nouveaux outils autour de la technologie BIM et de notre métier d’ingénieur dans les industries complexes. L’agilité et le savoir faire de Sonabim est très complémentaire avec notre présence sur de grands sites de production d’électricité, où l’outil numérique permet de nombreuses applications."
            Name="T.E.S / Directeur Général"
            company="Carlos SORIANO"
          />
        </div>
      </Box>

      <Box className={classes.TrustUsBottomBgWrapper}></Box>
    </Box>
  );
};

export default TheyTrustUS;
