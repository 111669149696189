import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    width: "100%",
    height: "1400px",
    "@media (max-width: 1000px)": {
      height: "auto",
      marginBottom: "-7rem",
    },
  },

  Heading: {
    "&.MuiTypography-root": {
      marginTop: "60px",
      textAlign: "center",
      color: "#1F3052",
      fontFamily: "'Poppins', sans-serif",
      fontStyle: "italic",
      fontWeight: "400",
      lineHeight: "93%",
      fontSize: "64px",
      letterSpacing: "-1.28px",

      "@media (max-width: 780px)": {
        fontSize: "60px",
      },

      "@media (max-width: 882px)": {
        fontSize: "50px",
      },

      "@media (max-width: 690px)": {
        fontSize: "40px",
      },
    },
  },

  lineBreaker: {
    display: "none",
    "@media (max-width:520px)": {
      display: "block",
    },
  },

  HeadingSpan: {
    textAlign: "center",
    color: "#49A995",
    fontFamily: "'Poppins', sans-serif",
    fontStyle: "italic",
    textTransform: "lowercase",
    fontWeight: "600",
    lineHeight: "93%",
    fontSize: "84px",
    letterSpacing: "-1.28px",

    "@media (max-width: 882px)": {
      fontSize: "75px",
    },

    "@media (max-width: 780px)": {
      fontSize: "68px",
    },

    "@media (max-width: 690px)": {
      fontSize: "50px",
    },
  },

  //

  CarouselContainer: {
    position: "relative",
  },
  SlideImage: {
    // filter: "brightness(0.5) !important",
  },
  Carousel: {
    "& .control-dots": {
      display: "flex",
      justifyContent: "center",
      gap: "1px",
    },
  },
  SlideImageContainer: {
    // width: "210px",
  },

  StaticText: {
    "&.MuiTypography-root": {
      textAlign: "center",
      color: "#FFF",
      fontSize: 112,
      fontFamily: "'Poppins', sans-serif",
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: "93%",
    },
  },
  BimText: {
    display: "block",
    fontWeight: 700,
    fontFamily: "'Poppins', sans-serif",
    fontStyle: "normal",
    marginLeft: "14rem",
  },
  StaticTextWrpper: {
    position: "absolute",
    top: 167,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  ContactUsButton: {
    "&.MuiButtonBase-root": {
      width: 187,
      height: 51,
      marginTop: "2rem",
    },
  },

  testimonials: {
    "&.MuiTypography-root": {
      marginTop: "124px",
      textAlign: "center",
      color: "#49A995",
      fontFamily: "'Poppins', sans-serif",
      fontStyle: "italic",
      fontWeight: "600",
      lineHeight: "93%",
      fontSize: "84px",
      letterSpacing: "-1.28px",

      "@media (max-width: 882px)": {
        fontSize: "75px",
      },

      "@media (max-width: 570px)": {
        fontSize: "65px",
      },

      "@media (max-width: 490px)": {
        fontSize: "55px",
      },

      "@media (max-width: 415px)": {
        fontSize: "45px",
      },
    },
  },

  SliderContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "52px",
    marginBottom: "52px",
  },

  Slider: {
    "&.slick-slider": {
      width: "97%",
      "& .slick-list": {
        "& .slick-dots": {
          marginBottom: "52px",
          "& .slick-track": {
            "& .slick-slide": {
              display: "flex",
              justifyContent: "center",
            },
          },
        },
      },
    },
    "& .slick-dots li button::before": {
      marginTop: "2rem",
    },
  },
  Test: {
    height: "81px !important",

    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
  },

  TestimonialsMainContainer: {
    width: "100%",
    maxWidth: "1296px",
    display: "flex",
    gap: "83px",
    margin: "auto",

    marginTop: "52px",

    "@media (max-width: 1179px)": {
      width: "96%",
      gap: "3px",
    },

    "@media (max-width: 1179px)": {
      width: "96%",
      gap: "3px",
    },

    "@media (max-width: 1000px)": {
      flexDirection: "column",
      alignItems: "center",
      gap: "30px",
    },
  },

  quotes: {
    display: "none",
    margin: "auto",
    marginBottom: "20px",
    "@media (max-width: 505px)": {
      display: "block",
    },
  },

  testimonialsContainer: {
    backgroundImage: `url("/Testimonial.png")`,
    width: "606.47px",
    height: "669.37px",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "@media (max-width: 688px)": {
      width: "100%",
    },

    "@media (max-width: 505px)": {
      backgroundImage: "none",
      backgroundColor: "#49A995",
      borderRadius: "50px",
      height: "auto",
      width: "90%",
      padding: "80px 9px 53px 9px ",
    },
  },

  HeadingText: {
    width: "82%",
    textAlign: "center",
    marginBottom: "10px",
  },

  Paragraphy: {
    color: "#1F3052",
    textAlign: "center",
    fontFamily: "inter",
    fontSize: "20.752px !importent",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "-0.83px",

    "@media (max-width: 505px)": {
      color: "#FFF",
      fontSize: "14.752px !important",
    },
  },

  TestimonialsImg: {
    marginTop: "31px",
  },

  JessyaInn: {
    "&.MuiTypography-root": {
      marginTop: "",
      textAlign: "center",
      color: "#1F3052",
      fontFamily: "Inter",
      fontSize: "18.158px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
      letterSpacing: "-0.726px",
    },
  },
  JessyaInnSpan: {
    textAlign: "center",
    color: "#1F3052",
    fontFamily: "Inter",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "-0.726px",
  },

  TestimonialsButton: {
    "&.MuiButtonBase-root": {
      marginTop: "52px",
      border: "1px solid #49A995",
      borderRadius: "6px",
      color: "#49A995",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
      padding: "27px 27px",
    },
  },

  ButtonContaainer: {
    width: "100%",
    textAlign: "center",
  },
  TrustUsBottomBgWrapper: {
    backgroundImage: `url("/TrustUsBottomBg.png")`,
    height: "184.225px",
    width: "100%",
    backgroundSize: "100% 110%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    position: "relative",
    marginTop: "12.1rem",

    "@media (max-width: 878px)": {
      marginTop: "0.1rem",
    },
  },
}));
